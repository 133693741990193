import React from "react"
import Layout from "../components/layout"
import Rating from "@material-ui/lab/Rating"
import {Link} from "gatsby"
import WaveSvg from "../assets/images/Wave.svg"
import SEO from "../components/seo"
import BreadCrumbs from "../components/bread-crumbs";
import "./all-landing-ui.scss"

const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    },
    {
        title: "Get Started",
        link: "/get-started",
    },
    {
        title: "Online Therapy",
        link: "/online-therapy",
    }

]

const navigateToProviderDetail = (slug) => {
    window.location.assign(`/online-therapy/providers/${slug}/`);
};

const sortByName = (a, b) => {
    const firstUserSplitValues = a.providerName.split(' ');
    const SecondUserSplitValues = b.providerName.split(' ');
    const user1 = firstUserSplitValues[firstUserSplitValues.length
    - 1].toUpperCase();
    const user2 = SecondUserSplitValues[SecondUserSplitValues.length
    - 1].toUpperCase();
    if (user1 < user2) {
        return -1;
    }
    if (user1 > user2) {
        return 1;
    }
    return 0;
};

export default function (props) {

    const providers = props.pageContext.data;
    providers.sort((a, b) => sortByName(a, b));
    return (
        <Layout>
            <SEO title="Our Providers"/>
            <BreadCrumbs currentPage={"All Providers"}  breadCrumbsList={breadCrumbsList}/>
            <div className="all-common-main">
                <div className="common-wrap-bg"
                     style={{backgroundImage: `url(${WaveSvg})`}}>
                    <h2 className="all-common-main-heading">Meet our
                        Providers</h2>
                    <p className="all-common-main-para"> Meet the dedicated
                        physicians, nurse practitioner, social
                        workers, coaches, and matchmakers that stand ready to
                        help you reach your goals. We’ve hand
                        selected our team because they’re great at what they do.
                        Check out their profiles to book an
                        appointment with them directly, or chat with a
                        matchmaker who can make recommendations based on
                        your needs.</p>
                    <div className="common-wrap">
                        <div className="common-list">
                            {
                                providers.map((provider, key) => {
                                    return (
                                        <div key={key}
                                             className="single-provider">
                                            <div
                                                className="single-provider-header">
                                                <div
                                                    className="single-provider-header-img">
                                                    {provider.providerImage
                                                    !== null ?
                                                        <img src={'https:'
                                                        + provider.providerImage?.file?.url}
                                                             alt="providerImg"
                                                             onClick={() => navigateToProviderDetail(
                                                                 provider.slug)}
                                                        />
                                                        :
                                                        <img src={require(
                                                            '../assets/images/default-provider-image.png')}
                                                             alt="providerImg"
                                                             onClick={() => navigateToProviderDetail(
                                                                 provider.slug)}
                                                        />
                                                    }
                                                </div>
                                                <div
                                                    className="single-provider-header-detail">
                                                    <h3 onClick={() => navigateToProviderDetail(
                                                        provider.slug)}>{provider.providerName}</h3>
                                                    <p>{provider.role}</p>
                                                    <a className="view-pro"
                                                       href={`/online-therapy/providers/${provider.slug}/`}>View
                                                        full profile <img
                                                            src={require(
                                                                "../assets/images/Right.svg")}
                                                            alt="Icon"/></a>
                                                </div>
                                            </div>
                                            <div
                                                className="single-rating-section">
                                                <div
                                                    className='single-rating-section-rating'>
                                                    <Rating name="read-only"
                                                            value={provider.rating}
                                                            readOnly
                                                            size='small'/>
                                                    <p className="rating-text">{provider.rating}</p>
                                                </div>
                                                <div
                                                    className="single-rating-section-totalViews">
                                                    {provider.totalReviews} reviews
                                                </div>

                                            </div>
                                            <div
                                                className="single-provider-body">
                                                <p className="single-provider-bio">
                                                    {provider.description?.description}
                                                </p>
                                                <Link className="outline-btn"
                                                      to={`/online-therapy/providers/${provider.slug}/`}
                                                >Connect</Link>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="after-common-wrap">
                <div className="after-common-inner">
                    <h4 className="after-common-head">Confidant Providers are
                        Here to Help</h4>
                    <p className="after-common-para">Our multi-disciplinary is
                        trained in the best practices that are shown to help you
                        succeed.
                        We’re committed to forming an alliance with you and
                        providing support so you can reach your goals.
                        We serve both individuals struggling with substance use
                        or mental health as well as their family members.
                        If you aren’t sure the best type of provider to meet
                        your needs you can always have a free consultation with
                        a Matchmaker.
                        <br/>
                        <br/>
                        Our Matchmakers are the connective fiber of our network
                        and can get you connected with the services that are
                        right for you.
                        Since there are no cookie-cutter programs at Confidant
                        your care plan will always be designed to meet your
                        specific needs.
                    </p>
                </div>
            </div>
        </Layout>
    )
}

